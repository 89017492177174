import React, {useEffect, useState} from 'react';
import Sensordata from './sensordata.tsx';


import './App.css';

function App() {
  const urlParams=new URLSearchParams(window.location.search); 
  const theme = (urlParams.get('colormode'));
  const auth=(urlParams.get('auth'));
  const devmode=(window.location.href.search('localhost') > 0);
  var [shtime, setShtime] = useState(new Date());
  var [ctheme, setCtheme] = useState(theme);
  console.log(theme);

  useEffect(() => {
    var themetimer = setInterval(() => setShtime(new Date()), 600000)

    document.title = "Smarthome - Kikerics";

    var hours=shtime.getHours();
    if (((hours <=8) ||(hours >= 22)) && (theme==='auto'))
      setCtheme('night')
    else if (theme==='auto')
      setCtheme('day');
    return function cleanup() {
      clearInterval(themetimer);
    }
  }, [shtime, theme]);


  if ((auth==='aZx4wjkQ3U') || (devmode))
  return (
    <div className="App" data-theme={ctheme}>
    <React.Fragment>
        
     <React.Suspense fallback={<div>Loading...</div>}>
       <Sensordata theme={theme}>
          
       </Sensordata>
     </React.Suspense>
   </React.Fragment>
 </div> 
  )
  else 
    return (
      <h1>Forbidden</h1>
      )
}

export default App;
