import React, {useState, useEffect} from 'react';

function useChecksum(value) {
  const [checksum, setChecksum] = useState(0);

  useEffect(() => {
    setChecksum(checksum => checksum + 1);
  }, [value]);

  return checksum;
}

export default function Weatherforecast (props) {
  const checksum = useChecksum(props);

  var wtheme = "pure";
  if ((props.theme==="dark") || (props.theme==="night")) wtheme="dark";
  if (props.theme==="day") wtheme="original";

    return (
      /*<div className='openweather' id="openweathermap-widget-11"></div>
      <div className='openweather'><a className="weatherwidget-io" href="https://forecast7.com/hu/47d2819d07/dunavarsany/" data-label_1="DUNAVARSÁNY" data-theme={wtheme} >DUNAVARSÁNY</a></div>*/
      <iframe width="250" height="250" title='koponyeg' key={checksum} id='koponyegframe'  frameBorder="0" src="https://koponyeg.hu/add-on-embed?w=250&r=1&c=dark"></iframe>
    )
}