import React, { useState, useEffect } from 'react';

export const CurrentTime = () => {
    var [timestamp, setTimestamp] = useState(new Date());

    var daynames = ['vasárnap','hétfő','kedd','szerda','csütörtök','péntek','szombat'];

    useEffect(() => {
        var timer = setInterval(() => setTimestamp(new Date()), 1000)
        return function cleanup() {
            clearInterval(timer);
        }
    });

    var startDate = new Date(timestamp.getFullYear(), 0, 1);
    var days = Math.floor((timestamp - startDate) /
        (24 * 60 * 60 * 1000));
         
    var weekNumber = Math.ceil(days / 7);    
    return(
        <div className="timeheader">
            <div className="timedata">{timestamp.toLocaleDateString()} {daynames[timestamp.getDay()]}, {weekNumber}. hét</div>
            <div className="timedata">{timestamp.toLocaleTimeString()}</div>
        </div>

    )
}