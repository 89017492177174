import React, { useState, useEffect } from 'react';
import {Sensorgroup, panelValues, panelValue, SensorPanelItem} from './sensorPanel.tsx';
import {CurrentTime} from './currenttime.tsx';
import Weatherforecast from './weatherPanel.tsx';

export default function Sensordata(theme: string) {
  const [sensors, initSensors] = useState([]);
  const [refreshInterval, setRefreshInterval] = useState(0);

  
  const fetchData = async () => {
    const response = await fetch('https://smarthome.kikerics.hu/sh_scripts/get_current_values.php?auth=aZx4wjkQ3U')
    if (!response.ok) {
      throw new Error('Data coud not be fetched!')
    } else {
    console.log('fetch');
      return response.json()
    }
  }

let searchPlace = (pvalue, array) => {
    let new_array = [];
    for (let i = 0; i < array.length; i++) {
        if (array[i].shs_place === pvalue) {
            new_array.push(array[i]);
        }
    }
    return new_array;
};

let searchSensorItem: panelValue = (sensors, place, stype) => {
  let new_val: panelValue = {placename: ""};
  searchSensorItem.placename = "";
  for (let i = 0; i < sensors.length; i++) {
      if ((sensors[i].shs_place === place) && (sensors[i].shs_type === stype)) {
          new_val.dt = sensors[i].shs_dt;
          new_val.minutes = sensors[i].minutespassed;
          new_val.value = parseFloat(sensors[i].shs_value);
          new_val.valtype = sensors[i].shs_type;
          new_val.placename = sensors[i].shs_place;
      }
  }
  return new_val;
};
  
const ShowsensorsImportant = (props) => {
  if (props.sensors[0]) {
      let garagesensors = searchPlace('garage', props.sensors);
      let outdoorsensors = searchPlace('outdoor_back', props.sensors);
      let indoorsensors = searchPlace('indoor', props.sensors);
      let garagePanel: panelValues;
      let outdoorValues: panelValues = {};
      let garageValues: panelValues = {};
      let indoorValues: panelValues = {};

      outdoorValues.mainvalue = searchSensorItem(sensors, "outdoor_calc", "temp");
      outdoorValues.subvalue = searchSensorItem(sensors, "outdoor_calc", "humidity");
      outdoorValues.thirdvalue = searchSensorItem(sensors, "outdoor", "temp");
      outdoorValues.thirdvalue.placename = '';
      indoorValues.mainvalue = searchSensorItem(sensors, "indoor", "temp");
      indoorValues.subvalue = searchSensorItem(sensors, "indoor", "humidity");
      indoorValues.thirdvalue = searchSensorItem(sensors, "indoor", "airquality");
      garageValues.mainvalue = searchSensorItem(sensors, "garage", "temp");
      garageValues.subvalue = searchSensorItem(sensors, "garage", "humidity");
      garageValues.thirdvalue = searchSensorItem(sensors, "heating", "temp");
      if (garageValues.thirdvalue.value < 27) {
        garageValues.commentline = 'padlófűtés ki';
      }
      else 
        garageValues.commentline = 'padlófűtés BE';
      garageValues.thirdvalue = searchSensorItem(sensors, "garage", "switch");
      console.log(garageValues.thirdvalue.value);
      garageValues.sw_state = (garageValues.thirdvalue.value == 1);
      garageValues.thirdvalue.placename = '';

/*      let outsensor = outdoorsensors;
      if (outdoor2sensors[0].shs_value <= outdoorsensors[0].shs_value) {
        outsensor = outdoor2sensors;
      }*/
      console.log('theme:');
      console.log(props.theme);
      if (props.theme.theme != 'echo') {
        return ( <div className='sensorlista'>
              <SensorPanelItem pvalues={outdoorValues} small={false} />
              <SensorPanelItem pvalues={garageValues} small={false} /> 
              <SensorPanelItem pvalues={indoorValues} small={false} />
              </div>
      ) }
      else {
        return ( <div className='sensorlista'>
        <SensorPanelItem pvalues={outdoorValues} small={false} />
        <SensorPanelItem pvalues={indoorValues} small={false} />
        </div> )        
      }
  }
} 

const ShowsensorsSmall = (props) => {
  if (props.sensors[0]) {
      let soilsensors = searchPlace('soil', props.sensors);
      let greenhousesensors = searchPlace('greenhouse', props.sensors);
      let atticsensors = searchPlace('attic', props.sensors);
      let heatingsensors = searchPlace('heating', props.sensors);
      if (props.theme.theme != 'echo') {
        return ( <div className='sensorlista'>
              <Sensorgroup sensors={greenhousesensors} size='1' />
              <Sensorgroup sensors={soilsensors} size='1' />
              <Sensorgroup sensors={atticsensors} size='1' />
              <Sensorgroup sensors={heatingsensors} size='1' />
              </div>
      ) }
      else {
        return ( <div className='sensorlista'>
              <Sensorgroup sensors={greenhousesensors} size='1' />
              <Sensorgroup sensors={atticsensors} size='1' />
              <Sensorgroup sensors={heatingsensors} size='1' />
              </div>
      )
      }
  }
} 

useEffect(() => {
  const getSensors = () => {
    console.log("valami");
        fetchData()
        .then((res) => {
          initSensors(res)
    })
        .catch((e) => {
          console.log(e.message)
        })
     }    

    if (refreshInterval === 0)
        {
            getSensors();
            const interval = setInterval(getSensors, 30000);
            setRefreshInterval(interval);
         }               
  }, [refreshInterval])

  return (
    <div className="pageDiv">
        <CurrentTime></CurrentTime>
         <div className='sensorDiv'>
          <div className='importants'>
            <ShowsensorsImportant sensors={sensors} theme={theme} />
          </div>
          <div className='nonimportants'>
            <ShowsensorsSmall sensors={sensors} theme={theme} />
          </div>
          <div className='weathercol'>
            <Weatherforecast sensors={sensors} />
          </div>
        </div>
    </div>
  )
}
